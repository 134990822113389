export const PATH_ACCOUNT_LOG = `/:account/log`

export default {
  path: PATH_ACCOUNT_LOG,
  name: 'Log Account',
  component: () => import('@/views/account/log/Log.vue'),
  meta: {
    title: 'Visualização de Logs da Conta',
    isAuthenticated: true,
  }
}
