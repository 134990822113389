import { ACCOUNT_ROUTER_NAME } from '../account/accounts'

export const PATH_SELLER_UPDATE = `/account/:account/saleschannel/:salesChannel/seller/:seller/update`
export const makePathSellerUpdate = (accountId, salesChannelId, sellerId, optionsByPaymentForm) =>
  PATH_SELLER_UPDATE
    .replace(':account', accountId)
    .replace(':salesChannel', salesChannelId)
    .replace(':seller', sellerId)
    .replace(':optionsByPaymentForm', optionsByPaymentForm);

export default {
  path: PATH_SELLER_UPDATE,
  name: 'Update Seller',
  component: () => import('@/views/seller/update/Update.vue'),
  meta: {
    title: 'Atualização de Seller',
    isAuthenticated: true,
    roles: ['admin', 'keyaccount'],
    redirectTo: ACCOUNT_ROUTER_NAME
  }
}
