import dayjs from '@/lib/dayjs'

export default (Vue) => {
  Vue.filter('dateList', (value) => {
    const pattern = `DD.MM.YYYY`
    const date = dayjs(value).format(pattern)
    return date
  })

  Vue.filter('dateListFull', (value) => {
    const date = dayjs(value)
    const isValid = date.isValid()
    if (!isValid) return "-"

    return date.format('DD.MM.YYYY/HH:mm')
  })
}
