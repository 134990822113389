<template>
  <ifc-main :isDev="isDev" data-app="true">
    <router-view :key="$route.fullPath" />
  </ifc-main>

</template>

<script>
import { mapMutations } from 'vuex'
import { IS_DEV } from './constants/enviroments'
import router from './router'

export default {
  name: 'App',
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  computed: {
    isDev() {
      return IS_DEV
    }
  },
  methods: {
    ...mapMutations('account', ['SET_SELECTED_ACCOUNT']),
  },
  destroyed: function() {
    return this.SET_SELECTED_ACCOUNT(null)
  },
  // eslint-disable-next-line space-before-function-paren
  mounted() {
    // eslint-disable-next-line space-before-function-paren
    function handleRouteChange(event) {
      router.push(event.detail.newRoute.replace('/', ''))
    }

    window.addEventListener('changeRoute', handleRouteChange)

    const currentUrl = window.location.pathname.split('/').pop();
    if (this.$route.path === '/') {
      router.push(`/${currentUrl}`);
    }

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', handleRouteChange)
    })
  }
}
</script>
<style lang="scss">
.v-application {
  background: #fafafc!important;
}
.ifc-dialog {
  line-break: normal !important;
}
.ifc-main {
  width: 100% !important;
}
</style>
