export const PATH_UPDATE_SALES_OPERATOR = '/salesoperators/:operator/update'
export const UPDATE_SALES_OPERATOR_ROUTER_NAME = 'UpdateSalesOperator'

export default {
  path: PATH_UPDATE_SALES_OPERATOR,
  name: UPDATE_SALES_OPERATOR_ROUTER_NAME,
  component: () => import('@/views/sales-operator/create-or-update/Update.vue'),
  meta: {
    title: 'Atualizar Vendedor',
    isAuthenticated: true,
  }
}
