import { IS_DEV } from "@/constants/enviroments"
import { jwtDecode } from "@/utils"
import { DEFAULT_AUTH, rolesDevelopment } from "./default-auth"

function convertListRoles (rolesAccount) {
  const authorized = !!rolesAccount?.length
  const roles = rolesAccount?.reduce((a, v) => ({ ...a, [v]: true}), {}) ?? {}

  return { ...roles, authorized }
}

function Auth () {
  this.listen = async (tokenObservable, eventBus, ctx) => {
    if (IS_DEV) {
      const tokenDecode = jwtDecode(DEFAULT_AUTH.authToken)
      const roles = convertListRoles(rolesDevelopment?.['cxaas-account']?.roles)

      this.token = DEFAULT_AUTH.authToken
      this.seller.id = DEFAULT_AUTH.sellerId
      this.account = DEFAULT_AUTH.store
      this.tenantId = DEFAULT_AUTH?.store?.id
      this.sessionId = tokenDecode?.session_state ?? ''
      this.userRoles = roles

      eventBus.apply(ctx, [this.event + 'update', this ])
      return
    }

    const { sessionStore, $authStore } = window.parent.moduleStateManamentSessionStore;

    this.token = $authStore.get().accessToken;
    this.activeTenantId = $authStore.get().activeTenantId;

    $authStore.subscribe((state) => {
      this.token = state.accessToken;
      this.tenantId = state.activeTenantId;
    });

    this.account = {
      id: this.tenantId,
      name: "lorem",
      slug: "lorem"
    }
    this.userRoles = {}
    this.allowed = (roles) => {
      return sessionStore.methods.allowed(roles)
    }
  }

  this.unsubscribe = (tokenObservable) => {
    return tokenObservable?.unsubscribe();
  }
}
function AppShellAuthObject (data) {
  this.id = data.id
}

Auth.prototype.event = 'appshell:auth:'
Auth.prototype.token = null
Auth.prototype.tenantId = null
Auth.prototype.sessionId = null
Auth.prototype.seller = new AppShellAuthObject({})
Auth.prototype.account = new AppShellAuthObject({})
Auth.prototype.userRoles = {}

export default new Auth()
