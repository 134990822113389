export const PATH_USER_LOG = `/users/:user/log`

export default {
  path: PATH_USER_LOG,
  name: 'Log user',
  component: () => import('@/views/users/log/Log.vue'),
  meta: {
    title: 'Visualização de Logs do Usuário',
    isAuthenticated: true,
  }
}
