import getUrl from '@/utils/get-service';
import axios from 'axios';

const URL = '/payment-forms'

export const remoteLoadOptionsPayment = async (accountId, salesChannel, seller) => {

  const { data } = await axios.get(`${getUrl(process.env.VUE_APP_API_PAYMENT_BASE_URL)}${URL}`, {
    params: {
      sellerId: seller,
      pageSize: 200,
    },
    headers: { 'x-tenant-id': accountId, 'x-channel-id': 'batatinha' }
  },
)

  return data;
}

export const updatePaymentForm = async (accountId, salesChannel, seller, id, body) => {

  const { data } = await axios.put(`${getUrl(process.env.VUE_APP_API_PAYMENT_BASE_URL)}${URL}/${id}`, {...body},
    {
      headers: { 'x-tenant-id': accountId, 'x-channel-id': salesChannel }
    }
)

  return data;
}
