export const PATH_VIEW_SALES_OPERATOR = '/salesoperators/:operator/view'
export const VIEW_SALES_OPERATOR_ROUTER_NAME = 'ViewSalesOperator'

export default {
  path: PATH_VIEW_SALES_OPERATOR,
  name: VIEW_SALES_OPERATOR_ROUTER_NAME,
  component: () => import('@/views/sales-operator/details/Details.vue'),
  meta: {
    title: 'Dados do Vendedor',
    isAuthenticated: true,
  }
}
