export const PATH_SALES_CHANNEL_VIEW = `/account/:account/saleschannel/:salesChannel/view`
export const makePathSalesChannelView = (accountId, salesChannelId) =>
  PATH_SALES_CHANNEL_VIEW
    .replace(':account', accountId)
    .replace(':salesChannel', salesChannelId);

export default {
  path: PATH_SALES_CHANNEL_VIEW,
  name: 'View Sales Channel',
  component: () => import('@/views/sales-channel/details/Details.vue'),
  meta: {
    title: 'Visualização do Canal de Venda',
    isAuthenticated: true,
  },
  props: true
}
