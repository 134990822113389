const MESSAGES = {
  account: {
    created: 'Cadastro da nova conta realizado com sucesso',
    updated: 'Conta alterada com sucesso'
  },
  location: {
    created: 'Cadastro do novo local realizado com sucesso',
    updated: 'Local alterado com sucesso'
  },
  salesChannel: {
    created: 'Canal de venda criado com sucesso',
    updated: 'Canal de venda alterado com sucesso'
  },
  seller: {
    created: 'Cadastro do novo seller realizado com sucesso',
    updated: 'Seller alterado com sucesso'
  },
}

export const configModalNotify = (type, context = null) => {
  const CONFIGS = {
    confirmUpdate: {
      text: 'Confirma a alteração?',
      type: 'warning',
      buttonLabel: 'Confirmar'
    },
    createdSuccess: {
      text: MESSAGES[context].created,
      type: 'success',
      buttonLabel: 'OK'
    },
    updatedSuccess: {
      text: MESSAGES[context].updated,
      type: 'success',
      buttonLabel: 'OK'
    },
    AlertData: {
      text: 'Os dados desse formulário serão perdidos. Deseja cancelar?',
      type: 'info',
      buttonLabel: 'OK'
    }
  }

  return CONFIGS[type]
}
