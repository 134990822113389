import LoggedLayout from '@/layout/Logged'
import LIST_ACCOUNTS from '@/router/routes/account/accounts'
import CREATE_ACCOUNT from '@/router/routes/account/create-account'
import LOG_ACCOUNT from '@/router/routes/account/log-account'
import UPDATE_ACCOUNT from '@/router/routes/account/update-account'
import VIEW_ACCOUNT from '@/router/routes/account/view-account'
import CREATE_LOCATION from '@/router/routes/location/create-location'
import UPDATE_LOCATION from '@/router/routes/location/update-location'
import VIEW_LOCATION from '@/router/routes/location/view-location'
import VIEW_SALES_CHANNEL from '@/router/routes/sales-channel/view-sales-channel'
import CREATE_SALES_OPERATOR from '@/router/routes/sales-operator/create-sales-operator'
import SALES_OPERATORS from '@/router/routes/sales-operator/sales-operators'
import UPDATE_SALES_OPERATOR from '@/router/routes/sales-operator/update-sales-operator'
import VIEW_SALES_OPERATOR from '@/router/routes/sales-operator/view-sales-operator'
import CREATE_SELLER from '@/router/routes/seller/create-seller'
import UPDATE_SELLER from '@/router/routes/seller/update-seller'
import VIEW_SELLER from '@/router/routes/seller/view-seller'
import UNAUTHORIZED from '@/router/routes/unauthorized/unauthorized'
import LOG_USER from '@/router/routes/user/log-user'
import LIST_USERS from '@/router/routes/user/users'
import VIEW_USER from '@/router/routes/user/view-user'

const ACCOUNT = [
  LIST_ACCOUNTS,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  VIEW_ACCOUNT,
  LOG_ACCOUNT
]

const LOCATION = [ CREATE_LOCATION, VIEW_LOCATION, UPDATE_LOCATION ]
const SALES_CHANNEL = [ VIEW_SALES_CHANNEL ]
const SELLER = [ CREATE_SELLER, VIEW_SELLER, UPDATE_SELLER ]
const USER = [ LIST_USERS, VIEW_USER, LOG_USER ]
const SALES_OPERATOR = [ SALES_OPERATORS, CREATE_SALES_OPERATOR, UPDATE_SALES_OPERATOR, VIEW_SALES_OPERATOR ]

export default [
  {
    path: '/',
    component: LoggedLayout,
    children: [ ...SALES_CHANNEL, ...ACCOUNT, ...LOCATION, ...SELLER, ...USER, ...SALES_OPERATOR, UNAUTHORIZED ]
  }
]
