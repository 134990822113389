import { ACCOUNT_ROUTER_NAME } from './accounts'

export const PATH_ACCOUNT_UPDATE = `/:account/update`

export default {
  path: PATH_ACCOUNT_UPDATE,
  name: 'Update Account',
  component: () => import('@/views/account/update/Update.vue'),
  meta: {
    title: 'Atualização de Conta',
    isAuthenticated: true,
    roles: ['admin', 'keyaccount'],
    redirectTo: ACCOUNT_ROUTER_NAME
  }
}
