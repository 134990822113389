import { remoteLoadAccount } from "@/services";

export default {
  async getAccount({ state, commit }, accountId) {
    if (state.selectedAccount?.id === accountId) return;

    const { data } = await remoteLoadAccount(accountId);
    commit('SET_SELECTED_ACCOUNT', data);
  }
}
