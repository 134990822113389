export const PATH_UNAUTHORIZED = '/unauthorized'
export const UNAUTHORIZED_ROUTER_NAME = 'Unauthorized'

export default {
  path: PATH_UNAUTHORIZED,
  name: UNAUTHORIZED_ROUTER_NAME,
  component: () => import('@/views/unauthorized/Unauthorized.vue'),
  meta: {
    title: 'Não Autorizado',
    isAuthenticated: false,
  },
}
