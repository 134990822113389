export const PATH_SALES_OPERATORS = '/salesoperators'
export const SALES_OPERATORS_ROUTER_NAME = 'SalesOperators'

export default {
  path: PATH_SALES_OPERATORS,
  name: SALES_OPERATORS_ROUTER_NAME,
  component: () => import('@/views/sales-operator/list/SalesOperators.vue'),
  meta: {
    title: 'Listagem de Vendedores',
    isAuthenticated: true,
  }
}
