import getUrl from "@/utils/get-service"

export const NODE_ENV = process.env.NODE_ENV
export const IS_DEV = (NODE_ENV === 'local' || NODE_ENV === 'development')
export const VUE_APP_API_BASE_URL = getUrl(process.env.VUE_APP_API_BASE_URL)
export const VUE_APP_API_ACCOUNT_SALESOPERATOR_BASE_URL = getUrl(process.env.VUE_APP_API_ACCOUNT_SALESOPERATOR_BASE_URL)
export const VUE_APP_API_IDENTITY_BASE_URL = getUrl(process.env.VUE_APP_API_IDENTITY_BASE_URL)
export const VUE_APP_API_TOKEN = window.parent.IFC_VARIABLES.auth.token
export const VUE_APP_TENANT_ID = window.parent.IFC_VARIABLES.headers['x-tenant-id']
export const VUE_APP_API_USER = getUrl(process.env.VUE_APP_API_ACCOUNT_USER_BASE_URL)
