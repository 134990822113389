import { accountApi } from '@/lib/axios'

const URL = '/log-mongo'

export const remoteLoadLogAccount = async (params) => {
  const orderDirection = params?.sortDesc?.[0] || params?.sortDesc?.[0] === undefined ? 'desc' : 'asc'


  const { data } = await accountApi.get(URL, {
    params: {
      page: params?.page,
      perPage: params?.itemsPerPage,
      orderBy: params?.sortBy?.[0] ?? 'date',
      accountId: params?.accountId,
      orderDirection
    }
  })

  return data
}
