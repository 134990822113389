import Vue from 'vue'
import Vuex from 'vuex'
import account from '@/store/account'
import RoleProfiles from '@/store/roleProfiles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    RoleProfiles
  }
})
