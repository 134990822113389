import axios from 'axios';
import { defaultHeadersWithTenant, defaultTimeout, interceptorsRequest } from './commons';

const instance = axios.create({
  baseURL: `${window.parent.IFC_VARIABLES.baseURL}/account/user`,
  timeout: defaultTimeout,
  headers: defaultHeadersWithTenant
})

instance.interceptors.request.use(interceptorsRequest, function (error) {
  return Promise.reject(error);
});

export default instance
