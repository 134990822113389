import { accountApi } from '@/lib/axios';
import { removeRecursiveFieldsThatValueIsEmpty } from '@/utils/obj'

const URL = '/accounts'

export const remoteLoadAccounts = async (params) => {

  let orderDirection = params?.sortDesc?.[0] || params?.sortDesc?.[0] === undefined ? 'desc' : 'asc'

  if (params?.sortBy?.[0] === 'active') {
    orderDirection = params?.sortDesc?.[0] || params?.sortDesc?.[0] === undefined ? 'asc' : 'desc'
  }

  const { data } = await accountApi.get(URL, {
    params: {
      page: params?.page || 0,
      perPage: params?.itemsPerPage,
      orderBy: params?.sortBy?.[0] ?? 'name',
      fromCreatedAt: params?.fromCreatedAt,
      atCreatedAt: params?.atCreatedAt,
      fromUpdatedAt: params?.fromUpdatedAt,
      atUpdatedAt: params?.atUpdatedAt,
      search: params?.search,
      orderDirection,
    }
  })

  return data
}

export const remoteLoadAccount = async (accountId) => {
  const { data } = await accountApi.get(`${URL}/${accountId}`)

  return data
}

export const remoteCreateAccount = async (account) => {
  const { data } = await accountApi.post(URL, { data: removeRecursiveFieldsThatValueIsEmpty(account) })

  return data
}

export const remoteUpdateAccount = async (account, id) => {
  const { data } = await accountApi.patch(`${URL}/${id}`, { data: removeRecursiveFieldsThatValueIsEmpty(account) })

  return data
}

export const remoteLoadLocations = async (accountId) => {
  const { data } = await accountApi.get(`${URL}/${accountId}/locations`, {
    params: {
      page: 0,
      perPage: Number.MAX_SAFE_INTEGER,
      orderBy: 'name',
      orderByDirection: 'asc',
    }
  })

  return data
}

export const remoteLoadSalesChannels = async (accountId) => {
  const { data } = await accountApi.get(`${URL}/${accountId}/saleschannels`, {
    params: {
      page: 0,
      perPage: Number.MAX_SAFE_INTEGER,
      orderBy: 'name',
      orderByDirection: 'asc',
    }
  })

  return data
}

export const remoteLoadSellers = async (accountId, salesChannelId) => {
  const { data } = await accountApi.get(`${URL}/${accountId}/saleschannels/${salesChannelId}/sellers`, {
    params: {
      page: 0,
      perPage: Number.MAX_SAFE_INTEGER,
      orderBy: 'name',
      orderByDirection: 'asc',
    }
  })

  return data
}
export const remoteChannel = async (accountId, salesChannelId) => {
  const { data } = await accountApi.get(`${URL}/${accountId}/saleschannels/${salesChannelId}`)

  return data
}
