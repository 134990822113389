import { accountApi } from '@/lib/axios'
import { removeRecursiveFieldsThatValueIsEmpty } from '@/utils/obj'

const makeURL = (accountId, locationId) => {
  const baseURL = `/accounts/${accountId}/locations`;
  if (!locationId) return baseURL;
  return `${baseURL}/${locationId}`;
}


export const remoteCreateLocation = async (accountId, location) => {
  const { data } = await accountApi.post(makeURL(accountId), { data:
    removeRecursiveFieldsThatValueIsEmpty(location)
   })

  return data
}

export const remoteLoadLocation = async (accountId, locationId) => {
  const { data } = await accountApi.get(makeURL(accountId, locationId))

  return data
}

export const remoteUpdateLocation = async (accountId, locationId, location) => {
  const { data } = await accountApi.patch(makeURL(accountId, locationId), { data: removeRecursiveFieldsThatValueIsEmpty(location) })

  return data
}

