export const PATH_USER_VIEW = `/users/:user/view`

export default {
  path: PATH_USER_VIEW,
  name: 'View User',
  component: () => import('@/views/users/details/Details.vue'),
  meta: {
    title: 'Visualização de Usuário',
    isAuthenticated: true,
  },
  props: true
}
