export const PATH_CREATE_SALES_OPERATOR = '/salesoperators/create'
export const CREATE_SALES_OPERATOR_ROUTER_NAME = 'CreateSalesOperator'

export default {
  path: PATH_CREATE_SALES_OPERATOR,
  name: CREATE_SALES_OPERATOR_ROUTER_NAME,
  component: () => import('@/views/sales-operator/create-or-update/Create.vue'),
  meta: {
    title: 'Cadastrar Vendedor',
    isAuthenticated: true,
  }
}
