import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptBR from '@/lang/pt-BR'
import enUS from '@/lang/en-US'
import numberFormats from '@/lang/numbers'
import { defineLanguage } from '@/utils'

const lang = defineLanguage()

const langs = {
  'pt-BR': ptBR,
  'en-US': enUS
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: lang,
  numberFormats,
  fallbackLocale: lang,
  messages: {
    [lang]: langs[lang] || ptBR
  }
})
