import { salesOperatorApi } from '@/lib/axios'
import { removeRecursiveFieldsThatValueIsEmpty } from '@/utils/obj'

const URL = '/sales-operator'

export const remoteLoadSalesOperators = async (params) => {
  let orderDirection = params?.sortDesc?.[0] || params?.sortDesc?.[0] === undefined ? 'desc' : 'asc'

  if (params?.sortBy?.[0] === 'isActive') {
    orderDirection = params?.sortDesc?.[0] || params?.sortDesc?.[0] === undefined ? 'asc' : 'desc'
  }

  const { data } = await salesOperatorApi.get(URL, {
    params: {
      page: params?.page,
      perPage: params?.itemsPerPage,
      orderBy: params?.sortBy?.[0] ?? 'fullName',
      search: params?.search,
      orderDirection
    }
  })

  return data
}

export const remoteLoadSalesOperator = async (id) => {
  const { data } = await salesOperatorApi.get(`${URL}/${id}`)

  return data
}

export const remoteUpdateSalesOperator = async (id, salesOperator) => {
  const { data } = await salesOperatorApi.patch(`${URL}/${id}`, { data: { salesOperator: removeRecursiveFieldsThatValueIsEmpty(salesOperator) } })

  return data
}

export const remoteCreateSalesOperator = async (salesOperator) => {
  const hasAddress = Object.values(salesOperator.address).some(value => !!value);
  const sanitizeSalesOperator = {
    ...salesOperator,
    address: hasAddress ? salesOperator.address : undefined
  }
  const { data } = await salesOperatorApi.post(URL, { data: { salesOperator: sanitizeSalesOperator } })

  return data
}

export const remoteGetSalesOperatorConfig = async () => {

   try {
    const { data } = await salesOperatorApi.get(`${URL}/config`)


    return data
   } catch {
    return {}
   }
}

export const remoteCreateSalesOperatorConfig = async ({login, attributes}) => {

  const loginConfig = {
    data: {
      config: {
        isActive: true,
        login,
        attributes
      }
    }
  }

  const { data } = await salesOperatorApi.post(`${URL}/config/login`, loginConfig)

  return data

}

export const remoteUpdateSalesOperatorConfig = async (id, {login, attributes}) => {
  const loginConfig = {
    data: {
      config: {
        login,
        attributes
      }
    }
  }

  const { data } = await salesOperatorApi.patch(`${URL}/config/login/${id}`, loginConfig)

  return data
}

export const remoteGetSalesOperatorCodeAvailable = async (code) => {
  try {
    const { status } = await salesOperatorApi.get(`${URL}/code/${code}`)
    return status === 204
  } catch (error) {
    throw new Error(error)
  }
}

export const remoteGetSalesOperatorProfiles = async () => {
   try {
    const { data } = await salesOperatorApi.get(`${URL}/profiles`)


    return data
   } catch {
    return {}
   }
}

export const remoteLoadSalesOperatorProfile = async (id) => {
  const { data } = await salesOperatorApi.get(`${URL}/profiles/${id}`)

  return data
}

export const remoteCreateSalesOperatorProfile = async (payload) => {
  const { data } = await salesOperatorApi.post(`${URL}/profiles`, { data: { ...removeRecursiveFieldsThatValueIsEmpty(payload) } })

  return data
}

export const remoteCreateSalesOperatorLogin = async (login) => {
  const { data } = await salesOperatorApi.post(`${URL}/login`, { data: { login } })

  return data
}

export const remoteCreateSalesOperatorLogout = async (id) => {
  const { data } = await salesOperatorApi.post(`${URL}/logout`, { data: { id } })

  return data
}


export const remoteCreateSalesOperatorValidateToken = async () => {
  const { data } = await salesOperatorApi.post(`${URL}/validate-token`, { data: {
    "accessToken": "accessToken"
    }
  })

  return data
}

export const remoteCreateSalesOperatorRefreshToken = async () => {
  const { data } = await salesOperatorApi.post(`${URL}/refresh-token`, { data: {
    "refreshToken": "refreshToken"
   } })

  return data
}
