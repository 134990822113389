import { ACCOUNT_ROUTER_NAME } from './accounts'

export const PATH_ACCOUNT_CREATE = '/create'

export default {
  path: PATH_ACCOUNT_CREATE,
  name: 'Create Account',
  component: () => import('@/views/account/create/Create.vue'),
  meta: {
    title: 'Criação de Conta',
    isAuthenticated: true,
    roles: ['admin'],
    redirectTo: ACCOUNT_ROUTER_NAME
  },
  props: true
}
