import MixinNotify from '@/mixins/notify'
import { SALES_OPERATORS_ROUTER_NAME } from '@/router/routes/sales-operator/sales-operators'
import { VIEW_SALES_OPERATOR_ROUTER_NAME } from '@/router/routes/sales-operator/view-sales-operator'
import { remoteCreateSalesOperator, remoteCreateSalesOperatorConfig, remoteGetSalesOperatorConfig, remoteLoadSalesOperator, remoteLoadSalesOperatorProfile, remoteUpdateSalesOperator, remoteUpdateSalesOperatorConfig } from '@/services'

export default {
  mixins: [MixinNotify],
  data: (vm) => ({
    $isMounted: !vm.$salesOperatorId,
    $config: undefined
  }),
  computed: {
    $salesOperatorId() {
      return this.$route.params?.operator
    },
    $title() {
      return this.$salesOperatorId ? "Atualizar Vendedor" : "Cadastrar Vendedor"
    },
    $breadcrumbs() {
      const routeData = { text: this.$t('sales-operator.breadcrumbs.seller_data'), to: { name: VIEW_SALES_OPERATOR_ROUTER_NAME, params: { operator: this.$salesOperatorId } }, exact: true }
      return [
        { text: this.$t('general.breadcrumbs.home'), to: '/' },
        { text: this.$t('sales-operator.breadcrumbs.sellers') },
        { text: this.$t('sales-operator.breadcrumbs.seller_list'), to: { name: SALES_OPERATORS_ROUTER_NAME }, exact: true },
        this.$salesOperatorId ? routeData : undefined,
        this.$salesOperatorId
          ? { text: this.$t('sales-operator.breadcrumbs.update_seller')}
          : { text: this.$t('sales-operator.breadcrumbs.create_seller')},
      ].filter(Boolean)
    },
  },
  beforeDestroy () {
    this.$root.$off('appshell:auth:update');
  },
  methods: {
    _getTerm(isActive) {
      const term = isActive ? {
        warning: 'desativação',
        success: 'ativado',
        error: 'desativar'
      } : {
        warning: 'ativação',
        success: 'desativado',
        error: 'ativar'
      };
      return term
    },
    $goBack() {
      return this.$router.push({ name: SALES_OPERATORS_ROUTER_NAME })
    },
    async $createSalesOperator(salesOperator) {
      try {
        const data = await remoteCreateSalesOperator(salesOperator)
        this.$toastify('Cadastro realizado com sucesso!', 'success')
        this.$router.push({ name: SALES_OPERATORS_ROUTER_NAME })
        return data
      } catch (error) {
        const messageError = error?.response?.data?.errors?.[0]?.detail
        let messageReturn = '';
        if (messageError.message && messageError.message.length > 0) {
          messageError.message.forEach((error) => {
            messageReturn += error + '<br/><br/>'
          });
        }

        if (typeof messageError[0]?.detail !== 'undefined' && typeof messageError[0]?.detail?.errorMessage !== 'undefined') {
            messageReturn += messageError[0].detail.errorMessage + '<br/><br/>'
        }
        const message = messageReturn || 'Houve um problema ao salvar o vendedor, tente novamente.'

        this.$handleNotify({
          text: message,
          type: 'error',
          buttonLabel: 'OK'
        })
      }
    },
    async $updateSalesOperator(salesOperatorId, salesOperator) {
      const isUpdate = Boolean(this.$salesOperatorId)
      try {
        const data = await remoteUpdateSalesOperator(salesOperatorId, salesOperator)
        this.$handleNotify({
          text: isUpdate ?
            'Vendedor atualizado com sucesso!':
            `O vendedor foi ${this._getTerm(salesOperator.isActive).success} com sucesso`,
          type: 'success',
          buttonLabel: 'OK'
        })

        return data
      } catch (error) {
        const message = error?.response?.data?.errors?.[0]?.detail || 'Houve um problema ao salvar o vendedor, tente novamente.'

        this.$handleNotify({
          text: isUpdate ?
            `Houve um problema ao ${this._getTerm(salesOperator.isActive).error}, tente novamente` :
            message,
          type: 'error',
          buttonLabel: 'OK'
        })

        throw Error(error)
      }
    },
    async $getSalesOperator(id) {
      try {
        this.$loading.show()
        const config = await this.$getConfig()
        this.$config = config
        return await remoteLoadSalesOperator(id)
      } catch (error) {
        this.$handleNotify({
          text: 'Houve um problema ao carrega o vendedor, tente novamente.',
          type: 'error',
          buttonLabel: 'OK'
        })

        return this.$router.push({ name: SALES_OPERATORS_ROUTER_NAME })
      } finally {
        this.$loading.hide()
        this.$isMounted = true
      }
    },
    async $getSalesOperatorProfile(id) {
      try {
        return await remoteLoadSalesOperatorProfile(id)
      } catch (error) {
      }
    },
    async $getConfig() {
      const { data } = await remoteGetSalesOperatorConfig()
      return data
    },
    async $updateConfig(salesOperatorId, payload) {
      await remoteUpdateSalesOperatorConfig(salesOperatorId, payload)
    },
    async $createConfig(payload) {
      await remoteCreateSalesOperatorConfig(payload)
    }
  }
}
