import store from '../index'

export default {
  namespaced: true,
  state: {
    data: [],
    expire: 0
  },
  getters: {
    getRoleProfiles(state) {
      // if state.data is empty, will be loaded from local storage 'account-roles_profiles'
      if (state.data.length === 0) {
        const roleProfiles = JSON.parse(localStorage.getItem('account-roles_profiles'))

        // if in localStorage 'account-roles_profiles' have any data, will be placed in state.data and state.expire
        if (roleProfiles && roleProfiles.data && roleProfiles.data.length > 0) {
          // set data from localStorage
          state.data = roleProfiles.data
          // set expire from localStorage
          state.expire = roleProfiles.expire
        }
      }

      // get now timestamp
      const now = new Date().getTime()

      // verify if is expired, returning a boolean
      const expired = !(Number(state.expire) > now)

      if (expired) {
        store.dispatch('RoleProfiles/updateRoleProfiles')
      }

      return {
        data: state.data,
        expire: state.expire,
        expired: !(Number(state.expire) > now)
      }
    }
  },
  actions: {
    // update the values in sales channel
    async updateRoleProfiles(context) {
      try {
        // get values from api service
        // const response = await getProfiles()
        const response = { data: { perfils: [] } }
        const roleProfiles = response.data.perfis.map((s) => {
          return { label: s.name, value: s.name }
        })

        // pass data from api to mutation 'roleProfilesMutation'
        await context.commit('UPDATE_ROLE_PROFILES', roleProfiles)
      } catch (e) {
        console.error('account/role_profiles error', e)
      }
    }
  },
  mutations: {
    async UPDATE_ROLE_PROFILES(state, roleProfiles) {
      const fiveMinutes = new Date().getTime() + (1000 * 60 * 5)
      // roleProfiles received in parameter is seted to state
      state.data = roleProfiles
      // defining the new expire timestamp
      state.expire = fiveMinutes
      localStorage.setItem('account-roles_profiles', JSON.stringify(state))
    }
  }
}
