import routes from '@/router/routes'
import { PATH_ACCOUNT } from '@/router/routes/account/accounts'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    ...routes,
    {
      path: '*',
      redirect: {
        path: PATH_ACCOUNT
      }
    }
  ]
})
// beforeEach(router)

export default router
