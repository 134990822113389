export const PATH_USERS = '/users'

export default {
  path: PATH_USERS,
  name: 'Users',
  component: () => import('@/views/users/list/Users.vue'),
  meta: {
    title: 'Listagem de Usuários',
    isAuthenticated: true,
  }
}
