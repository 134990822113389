import { ACCOUNT_ROUTER_NAME } from '../account/accounts'

export const PATH_LOCATION_CREATE = '/account/:account/location/create'
export const makePathLocationCreate = (accountId) => PATH_LOCATION_CREATE.replace(':account', accountId);

export default {
  path: PATH_LOCATION_CREATE,
  name: 'Create Location',
  component: () => import('@/views/location/create/Create.vue'),
  meta: {
    title: 'Cadastro de Local',
    isAuthenticated: true,
    roles: ['admin', 'keyaccount'],
    redirectTo: ACCOUNT_ROUTER_NAME
  },
  props: true
}
