import getUrl from '@/utils/get-service';
import axios from 'axios';
import { defaultHeaders, defaultTimeout } from './commons';

const instance = axios.create({
  baseURL: getUrl(process.env.VUE_APP_API_BASE_URL),
  timeout: defaultTimeout,
  headers: defaultHeaders
})

export default instance
