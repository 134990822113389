export const PATH_ACCOUNT_VIEW = `/account/:account/view`
export const makePathAccountView = (accountId) => PATH_ACCOUNT_VIEW.replace(':account', accountId);

export default {
  path: PATH_ACCOUNT_VIEW,
  name: 'View Account',
  component: () => import('@/views/account/details/Details.vue'),
  meta: {
    title: 'Visualização de Conta',
    isAuthenticated: true,
  },
  props: true
}
