import { ACCOUNT_ROUTER_NAME } from '../account/accounts'

export const PATH_LOCATION_UPDATE = `/account/:account/location/:location/update`
export const makePathLocationUpdate = (accountId, locationId) =>
  PATH_LOCATION_UPDATE
    .replace(':account', accountId)
    .replace(':location', locationId);

export default {
  path: PATH_LOCATION_UPDATE,
  name: 'Update Location',
  component: () => import('@/views/location/update/Update.vue'),
  meta: {
    title: 'Atualização de Local',
    isAuthenticated: true,
    roles: ['admin', 'keyaccount'],
    redirectTo: ACCOUNT_ROUTER_NAME
  }
}
