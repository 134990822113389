export const PATH_ACCOUNT = '/accounts'
export const ACCOUNT_ROUTER_NAME = 'Account'

export default {
  path: PATH_ACCOUNT,
  name: ACCOUNT_ROUTER_NAME,
  component: () => import('@/views/account/list/Accounts.vue'),
  meta: {
    title: 'Listagem de Contas',
    isAuthenticated: true,
  },
  props: true
}
