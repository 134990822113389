import { accountApi } from '@/lib/axios'

const makeURL = (accountId, salesChannelId) => {
  const baseURL = `/accounts/${accountId}/saleschannels`;
  if (!salesChannelId) return baseURL;
  return `${baseURL}/${salesChannelId}`;
}

export const remoteLoadSalesChannel = async (accountId, salesChannelId) => {
  const { data } = await accountApi.get(makeURL(accountId, salesChannelId))

  return data
}

export const remoteCreateSalesChannel = async (accountId, channel) => {
  const { data } = await accountApi.post(makeURL(accountId), { data: channel })

  return data
}

export const remoteUpdateSalesChannel = async (accountId, salesChannelId, channel) => {
  const { data } = await accountApi.patch(makeURL(accountId, salesChannelId), { data: channel })

  return data
}
