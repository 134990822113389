const getTenant = () => {
  return window.parent.IFC_VARIABLES.headers['x-tenant-id']
}

const xTenantId = { 'X-Tenant-Id': getTenant() }

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const defaultHeadersWithTenant = {
  'Content-Type': 'application/json',
  ...xTenantId
}

if (process.env.NODE_ENV === 'local') {
  defaultHeadersWithTenant['Access-Control-Allow-Origin'] = '*'
  defaultHeaders['Access-Control-Allow-Origin'] = '*'
}


function interceptorsRequest (config) {

  return {
    ...config,
    headers: {
      ...config.headers,
      'X-Tenant-Id': getTenant(),
      'X-Channel-Id': '123'
    }
  };
}

const defaultTimeout = 10000

export {
  defaultHeaders,
  defaultHeadersWithTenant, defaultTimeout, interceptorsRequest
}

