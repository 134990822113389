export const removeRecursiveFieldsThatValueIsEmpty = (obj) => {
  for (const key in obj) {
      if ({}.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'string' && obj[key].trim() === '') {
              delete obj[key];
          } else if (Array.isArray(obj[key]) && obj[key].length === 0) {
              delete obj[key];
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
              removeRecursiveFieldsThatValueIsEmpty(obj[key]);
              if (Object.keys(obj[key]).length === 0) {
                  delete obj[key];
              }
          }
      }
  }
  return obj;
}
