export const PATH_SELLER_VIEW = `/account/:account/saleschannel/:salesChannel/seller/:seller/view`
export const makePathSellerView = (accountId, salesChannelId, sellerId) =>
  PATH_SELLER_VIEW
    .replace(':account', accountId)
    .replace(':salesChannel', salesChannelId)
    .replace(':seller', sellerId);

export default {
  path: PATH_SELLER_VIEW,
  name: 'View Seller',
  component: () => import('@/views/seller/details/Details.vue'),
  meta: {
    title: 'Visualização de Seller',
    isAuthenticated: true,
  },
  props: true
}
