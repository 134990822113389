export default {
  bind(el, _, vnode) {
    el.addEventListener('input', (event) => {
      let value = event.target.value.replace(/\D/g, ''); // Remove tudo que não é dígito


      if (value.length === 0) {
        event.target.value = '';
        vnode.componentInstance.$emit('input', ''); // Atualiza o valor no v-model
        return
      }

      value = (parseFloat(value) / 100); // Divide por 100 e mantém duas casas decimais

      const numberFormat = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      const formattedValue = numberFormat.format(value);

      event.target.value = formattedValue;
      vnode.componentInstance.$emit('input', formattedValue); // Atualiza o valor no v-model
    });
  },
}