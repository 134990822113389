export default {
  methods: {
    $handleNotify (objectNotify, callback = null, errorCallback = null) {
      this.$notify(objectNotify).then(() => {
        callback?.()
      }).catch(() => {
        errorCallback?.()
      })
    }
  }
}
