import getUrl from '@/utils/get-service';
import axios from 'axios';
import {  defaultTimeout } from './commons';

const instance = axios.create({
  baseURL: getUrl(process.env.VUE_APP_API_PAYMENT_BASE_URL),
  timeout: defaultTimeout,
})

export default instance
