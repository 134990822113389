import { ACCOUNT_ROUTER_NAME } from '../account/accounts'

export const PATH_SELLER_CREATE = `/account/:account/saleschannel/:salesChannel/seller/create`
export const makePathSellerCreate = (accountId, salesChannelId) =>
  PATH_SELLER_CREATE
    .replace(':account', accountId)
    .replace(':salesChannel', salesChannelId);

export default {
  path: PATH_SELLER_CREATE,
  name: 'Create Seller',
  component: () => import('@/views/seller/create/Create.vue'),
  meta: {
    title: 'Cadastro de Seller',
    isAuthenticated: true,
    roles: ['admin', 'keyaccount'],
    redirectTo: ACCOUNT_ROUTER_NAME
  },
  props: true
}
