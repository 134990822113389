import Validator from '@infralabs/infra-components-vue/dist/lib/validator'
import validatorIsEmail from 'validator/lib/isEmail'
import validatorIsAlphanumeric from 'validator/lib/isAlphanumeric'
import validatorIsURL from 'validator/lib/isURL'


class ValidatorAccount extends Validator {
  isURLOptional (message) {
    this.validation.push([
      (val) => {
        if (!val) return true;
        try {
         const isURL = new URL(val);
         if (isURL) return true;
         return message || 'URL inválida';
        } catch(e) {
          return message || 'URL inválida';
        }
      }
    ])
    return this
  }

  isAlphanumeric (message) {
    this.validation.push([
      (val) => !val || (validatorIsAlphanumeric(val) || (message || 'Somente caracteres alfanuméricos'))
    ])
    return this
  }

  isEmail (message) {
    this.validation.push([
      (val) => !val || (validatorIsEmail(val) || (message || 'E-mail inválido'))
    ])
    return this
  }

  isURL (message, options = {}) {
    this.validation.push([
      (val) => !val || (validatorIsURL(val, options) || (message || 'URL inválida'))
    ])
    return this
  }

  minLength (message, minLength) {
    this.validation.push([
      val => !val || (val.length >= minLength || (message || `Minímo de caracteres ${minLength}`))
    ])
    return this
  }

  maxLength (message, maxLength) {
    this.validation.push([
      val => !val || (val.length <= maxLength || (message || `Máximo de caracteres ${maxLength}`))
    ])
    return this
  }
}

export default (Vue) => {
  Vue.mixin({
    methods: {
      $validator: () => new ValidatorAccount()
    }
  })
}
