import { accountApi } from '@/lib/axios'
import { removeRecursiveFieldsThatValueIsEmpty } from '@/utils/obj'

const makeURL = (accountId, salesChannelId, sellerId) => {
  const baseURL = `/accounts/${accountId}/saleschannels/${salesChannelId}/sellers`;
  if (!sellerId) return baseURL;
  return `${baseURL}/${sellerId}`;
}

export const remoteCreateSeller = async (accountId, salesChannelId, seller) => {
  const { data } = await accountApi.post(makeURL(accountId, salesChannelId), { data: removeRecursiveFieldsThatValueIsEmpty(seller) })

  return data
}

export const remoteLoadSeller = async (accountId, salesChannelId, sellerId) => {
  const { data } = await accountApi.get(makeURL(accountId, salesChannelId, sellerId))

  return data
}

export const remoteUpdateSeller = async (accountId, salesChannelId, sellerId, seller) => {
  const { data } = await accountApi.patch(makeURL(accountId, salesChannelId, sellerId), { data: removeRecursiveFieldsThatValueIsEmpty(seller) })

  return data
}

