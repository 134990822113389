import { VUE_APP_API_ACCOUNT_SALESOPERATOR_BASE_URL } from '@/constants/enviroments';
import axios from 'axios'
import { defaultHeadersWithTenant, defaultTimeout, interceptorsRequest } from './commons'

const instance = axios.create({
  baseURL: VUE_APP_API_ACCOUNT_SALESOPERATOR_BASE_URL,
  timeout: defaultTimeout,
  headers: defaultHeadersWithTenant
})

instance.interceptors.request.use(interceptorsRequest, function (error) {
  return Promise.reject(error);
});

export default instance
