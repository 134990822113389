<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { makePathAccountView } from '@/router/routes/account/view-account'

export default {
  name: 'LayoutLogged',
  beforeCreate: function () {
    this.$nextTick(() => {
      const activeTenantId = window.parent.IFC_VARIABLES?.activeTenantId
      if (
          activeTenantId &&
          activeTenantId !== '000000000000000000000000' &&
          this.$route.fullPath === '/accounts'
        ) {
          const pathAccount =  makePathAccountView(activeTenantId);
          return this.$router.push(pathAccount);
      }
    })
  },
}
</script>
