export const Patterns = {
  phone: {
    pattern: /^([\d]{2})\(*([\d]{2})\)*([\d]{4,5})-*([\d]{4})/,
    replace: "$1 ($2) $3-$4"
  },
  cpf: {
    pattern: /^([\d]{3})\.*([\d]{3})\.*([\d]{3})-*([\d]{2})/,
    replace: "$1.$2.$3-$4"
  },
  cnpj: {
    pattern: /^([\d]{2})\.*([\d]{3})\.*([\d]{3})\/*([\d]{4})-*([\d]{2})/,
    replace: "$1.$2.$3/$4-$5"
  },
  cep: {
    pattern: /^([\d]{2})\.*([\d]{3})-*([\d]{3})/,
    replace: "$1.$2-$3"
  }
}
