export const PATH_LOCATION_VIEW = `/account/:account/location/:location/view`
export const makePathLocationView = (accountId, locationId) =>
  PATH_LOCATION_VIEW
    .replace(':account', accountId)
    .replace(':location', locationId);

export default {
  path: PATH_LOCATION_VIEW,
  name: 'View Location',
  component: () => import('@/views/location/details/Details.vue'),
  meta: {
    title: 'Visualização do Local',
    isAuthenticated: true,
  },
  props: true
}
